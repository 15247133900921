.auth {
  background: #f0f2f5;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 425px;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  margin: 0 auto;
  gap: 8px;
}

.form {
  width: 70%;
}
