body {
  width: 50px;
  margin: 0;

  background: #fff;
}

.site-layout-content {
  min-height: 360px;
  padding: 24px;

  background: #fff;
}

.ant-breadcrumb {
  margin: 16px 0 !important;
}
