.finance-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.finance-item {
  display: flex;
  justify-content: space-between;

  & > span:first-child {
    padding-right: 4px;
  }
}
