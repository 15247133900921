.fund-creation-wrapper {
  display: flex;
  gap: 32px;

  &_steps {
    position: sticky;
    top: 50px;

    height: fit-content;
  }

  &_form {
    width: 80%;
  }
}
