.logo {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  margin: 16px;

  transition: font-size 0.2s;

  color: #fff;

  font-size: 16px;

  &_collapsed {
    font-size: 10px;
  }
}

.ant-menu-title-content {
  white-space: normal !important;

  line-height: 110%;
}

.ant-menu-item {
  display: flex;
}

.menu > li > span:last-child() {
  text-overflow: unset !important;
}
